module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lmt-radio-signal-sense","defaultLang":"en-gb","sharpKeys":[{},"icon","logo","poster","card_image","project_lead_logo","map_desktop","map_mobile"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LMT Radio Signal Sense","short_name":"LMT Radio2S","start_url":"/","background_color":"#2D2D2D","theme_color":"#FF6F35","display":"minimal-ui","icon":"src/images/favicon.png","icons":[{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
